import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignature, faSignatureLock, faSignatureSlash } from '@fortawesome/pro-light-svg-icons';
import Avatar from '../../../components/avatar';
import { IUser } from '@/features/people/types';
import { parseName } from '@/utils/helpers';

export const PartyDisplayer = ({
	signature,
	should_sign,
	uid,
	user,
}: {
	signature?: Record<string, any>;
	should_sign: boolean;
	uid: string;
	user: IUser;
}) => {
	return (
		<div className="relative" key={uid}>
			<Avatar size={40} email={user.email} tooltip={parseName(user)} />
			<div
				className="absolute flex items-center justify-center w-5 h-5 text-black rounded-full pointer-events-none -bottom-1 -left-1 bg-slate"
				style={{
					background:
						should_sign && signature ? 'rgb(134, 239, 172)' : should_sign && !signature ? 'rgb(252, 165, 165)' : 'rgb(216 216 216)',
				}}
			>
				{should_sign ? (
					<div>{signature ? <FontAwesomeIcon icon={faSignature} size="sm" /> : <FontAwesomeIcon icon={faSignatureSlash} size="sm" />}</div>
				) : (
					<div>
						<FontAwesomeIcon icon={faSignatureLock} size="sm" />
					</div>
				)}
			</div>
		</div>
	);
};
