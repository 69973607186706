import { AgreementOwnerField } from '@/features/fields/components/fields';
import { TagsViewer } from '@/features/tags/components/TagsViewer';
import { IColumn } from '@/types/tables';
import { parseName } from '@/utils/helpers';
import { DateCell, ResponsiblesCell, SnippetCell } from './cells';
import { CustomerCell } from './cells/CustomerCell';
import { PartiesCell } from './cells/PartiesCell';
import { TalentsCell } from './cells/TalentsCell';
import { CVCell } from './cells/cvpartners/CVCell';
import { DepartmentCell } from './cells/cvpartners/DepartmentCell';
import { ProfileImageCell } from './cells/cvpartners/ProfileImageCell';
import { ProfilLinkCell } from './cells/cvpartners/ProfileLinkCell';
import { RolesCell } from './cells/cvpartners/RolesCell';
import { TagsCell } from './cells/cvpartners/TagsCell';
import { AllocationCell } from './cells/people/AllocationCell';
import { CompanyCell } from './cells/people/CompanyCell';
import { UserFilterCell } from './cells/people/UserFilterCell';
import { UserTypeCell } from './cells/people/UserTypeCell';
import { cells } from './utils/cells';

export interface ICell {
	value: any;
	record?: any;
	column?: IColumn;
}

export const Cell = (props: ICell) => {
	const Component = cells[props.column.datatype];

	if (!Component) {
		if (typeof props.value !== 'string') {
			return <p className="text-xs text-gray-300">Invalid data</p>;
		}
		return props.value;
	}

	switch (props.column.datatype) {
		case 'agreement_owner': {
			return <AgreementOwnerField value={props.value} readOnly />;
		}
		case 'tags': {
			return <TagsViewer tags={props.value} shownAmount={9999} />;
		}
		case 'date': {
			return <DateCell {...props} />;
		}
		case 'date_range': {
			return <DateCell {...props} />;
		}
		case 'multi_date_range': {
			return <DateCell {...props} />;
		}
		case 'snippet': {
			return <SnippetCell {...props} />;
		}
		case 'responsibles': {
			return <ResponsiblesCell {...props} />;
		}
		case 'owners':
		case 'contract_suppliers':
		case 'talents':
		case 'viewers': {
			return <PartiesCell {...props} />;
		}
		case 'customer': {
			return <CustomerCell {...props} />;
		}
		case 'task_talents': {
			return <TalentsCell {...props} />;
		}
		case 'people_user': {
			return <UserFilterCell {...props} />;
		}
		case 'people_fullname': {
			return parseName(props.record);
		}
		case 'people_company': {
			return <CompanyCell {...props} />;
		}
		case 'tags': {
			return <TagsViewer tags={props.value} />;
		}
		case 'type': {
			return <UserTypeCell {...props} />;
		}
		case 'people_allocation': {
			return <AllocationCell {...props} />;
		}
		case 'cvp_profile_image': {
			return <ProfileImageCell {...props} />;
		}
		case 'cvp_cv': {
			return <CVCell {...props} />;
		}
		case 'cvp_tags': {
			return <TagsCell {...props} />;
		}
		case 'cvp_profile_link': {
			return <ProfilLinkCell {...props} />;
		}
		case 'cvp_roles': {
			return <RolesCell {...props} />;
		}
		case 'cvp_department': {
			return <DepartmentCell {...props} />;
		}

		default: {
			return <Component {...props} />;
		}
	}
};
