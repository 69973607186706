import { ITable } from '@/types/tables';
import { isValidDate } from '@/utils/helpers';
import { orderBy } from 'lodash';

interface IUseSortableData {
	data: any[];
	sorter: ITable['defaultsort'];
}

export const useSortableData = ({ data, sorter }: IUseSortableData) => {
	const { datatype, dataindex, order } = sorter;

	if (!dataindex) return data;
	switch (datatype) {
		case 'date': {
			const orderVal = order === 'desc' ? 0 : null;
			return orderBy(data, (row) => (isValidDate(row[dataindex]) ? new Date(row[dataindex]).getTime() : orderVal), order);
		}
		case 'date_range':
		case 'price_range': {
			return orderBy(data, (row) => row[dataindex]?.[0], order);
		}
		case 'select': {
			return orderBy(data, (row) => row[dataindex]?.text, order);
		}
		case 'multi_date_range':
		case 'tags':
		case 'attachments':
		case 'responsibles':
		case 'multiselect':
		case 'owners':
		case 'contract_suppliers':
		case 'talents':
		case 'viewers': {
			return orderBy(data, (row) => row[dataindex]?.length, order);
		}
		case 'progress_bar': {
			return orderBy(data, (row) => row[dataindex].offer_status, order);
		}
		case 'agreement_owner': {
			return orderBy(data, (row) => row[dataindex]?.name, order);
		}
		case 'fte': {
			return orderBy(data, (row) => (row[dataindex] ?? 0) / 37, order);
		}
		case 'cv_templates':
		case 'extended_task': {
			return orderBy(data, (row) => !!row[dataindex], order);
		}
		default: {
			return orderBy(data, dataindex, order);
		}
	}
};
